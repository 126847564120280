@import '../../../../scss/theme-bootstrap';

// Variables used only for this part of gnav - we want them here. :)
$grid1-col: 188;
$grid1-gutter: 7.5;
$grid1-gutter--xxlarge: 46;
$grid1-wrap-only: 1119; // links with tout: 1440 - 88 - 188 - 45
$grid1-wrap-full: 1352; // no tout col: 1440 - 88
$grid2-col: 230;
$grid2-gutter: 40;
$grid2-wrap: 1080;

.gnav-section-formatter {
  @include breakpoint($landscape-up) {
    padding-#{$rdirection}: 48px;
  }
  &[aria-expanded='true'] {
    // Mobile specific expanded state with parent group expanded
    .gnav-mobile-group-active & {
      @include breakpoint($landscape-down) {
        @include swap_direction(padding, 0 0 200px 0);
        background: $color-white;
        #{$ldirection}: 0;
        height: 100vh;
        overflow-y: scroll;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
      }
      .gnav-section-formatter__content-wrapper {
        @include breakpoint($landscape-down) {
          padding-#{$ldirection}: $gnav-mobile-hpad--inner;
        }
      }
      .gnav-section-formatter__link-arrow {
        @include breakpoint($landscape-down) {
          display: none;
        }
      }
    }
  }
  &__link {
    cursor: pointer;
    display: block;
    height: $gnav-mobile-vpad--lg;
    line-height: $gnav-mobile-vpad--lg;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    &:hover,
    &:visited {
      text-decoration: none;
    }
    @include breakpoint($landscape-up) {
      display: inline-block;
      letter-spacing: get-letter-spacing(170);
      height: auto;
      line-height: 16px; // Intentionally px based for the box shadow
      &:hover,
      &:visited {
        @include link-underline;
      }
    }
    .gnav-header-sections__section-content[aria-hidden='true']
      .gnav-section-group__section:only-child
      & {
      display: none;
    }
    span {
      font-family: $font--lars;
      font-size: 12px;
      letter-spacing: get-letter-spacing(120);
      text-transform: uppercase;
      @include breakpoint($landscape-up) {
        font-weight: normal;
        letter-spacing: get-letter-spacing(170);
      }
      .gnav-section-formatter[aria-expanded='true'] & {
        font-weight: 700;
        letter-spacing: get-letter-spacing(120);
        @include breakpoint($landscape-up) {
          display: inline-block;
          font-weight: normal;
          text-shadow: 0 0 0.65px $color-black, 0 0 0.65px $color-black;
          letter-spacing: get-letter-spacing(170);
          line-height: 16px; // Intentionally px based for the box shadow
          &:hover,
          &:visited {
            @include link-underline;
            color: $color-black;
          }
        }
      }
    }
  }
  &__link-arrow {
    display: block;
    height: $gnav-icon--sm;
    position: absolute;
    #{$rdirection}: $gnav-mobile-hpad--outer;
    top: 25px;
    transition: all 0.3s;
    width: $gnav-icon--sm;
    // gnav section expanded state
    .gnav-section-formatter[aria-expanded='true'] & {
      transform: rotate(90deg);
    }
    @include breakpoint($landscape-up) {
      display: none; // arrow is mobile only
    }
  }
  &__content-wrapper {
    display: flex;
    flex-direction: column;
    height: auto;
    opacity: 1;
    position: relative;
    width: 100%;
    @include breakpoint($landscape-up) {
      background-color: $color-cream;
      flex-direction: row;
      justify-content: space-between;
      max-height: calc(100% - 205px); // subtract gnav height plus groups height
      max-width: 100%;
      overflow-y: auto;
      padding: 20px $container-pad-landscape 25px;
      position: fixed;
      width: 100%;
      display: none;
      left: 50%; // using for centering - no RTL needed
      transform: translate(-50%, 0);
    }
    &[aria-hidden='false'] {
      @include breakpoint($landscape-up) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
    // gnav section hidden state
    .gnav-section-formatter[aria-expanded='false'] & {
      flex-direction: column;
      flex-basis: auto;
      height: 0;
      opacity: 0;
      position: absolute;
      display: none;
    }
  }
  &__gnav-links-wrapper {
    -webkit-tap-highlight-color: transparent;
    display: flex;
    flex-direction: column;
    height: 0;
    order: 1;
    width: 100%;
    @include breakpoint($landscape-up) {
      flex-direction: row;
      max-width: 100%;
    }
    .gnav-section-formatter--tout-enabled & {
      @include breakpoint($landscape-up) {
        max-width: 82.8676%;
        width: 82.8676%;
      }
    }
    .gnav-section-formatter--tout-enabled.gnav-section-formatter--mobile-tout-first & {
      @include breakpoint($landscape-down) {
        order: 2;
      }
    }
    .gnav-section-formatter[aria-expanded='true'] & {
      height: auto;
      @include breakpoint($landscape-up) {
        height: 100%;
      }
    }
    // default expanded layout is grid-1 from cms selection
    // .gnav-section-formatter--grid-1 (6 even columns)
    > div {
      padding: 0;
      @include breakpoint($landscape-up) {
        flex: 0 1 fit-content;
        margin-#{$rdirection}: percentage($grid1-gutter / $grid1-wrap-full);
      }
      .gnav-section-formatter--grid-1.gnav-section-formatter--tout-enabled & {
        @include breakpoint($landscape-up) {
          flex: 0 percentage($grid1-col / $grid1-wrap-only);
          margin-#{$rdirection}: percentage($grid1-gutter / $grid1-wrap-only);
        }
      }
      .gnav-section-formatter--grid-2 & {
        @include breakpoint($landscape-up) {
          flex: 0 percentage($grid2-col / $grid2-wrap);
          margin-#{$rdirection}: percentage($grid2-gutter / $grid2-wrap);
        }
      }
      &:last-child {
        .gnav-section-formatter--grid-1.gnav-section-formatter--tout-enabled & {
          @include breakpoint($landscape-up) {
            margin-#{$rdirection}: percentage($grid1-gutter / $grid1-wrap-only);
          }
        }
        .gnav-section-formatter--grid-2 & {
          @include breakpoint($landscape-up) {
            margin-#{$rdirection}: percentage($grid2-gutter / $grid2-wrap);
          }
        }
      }
      &:nth-child(6) {
        .gnav-section-formatter--grid-1.gnav-section-formatter--tout-enabled & {
          @include breakpoint($landscape-up) {
            margin-#{$rdirection}: 0;
          }
        }
        .gnav-section-formatter--grid-2 & {
          @include breakpoint($landscape-up) {
            margin-#{$rdirection}: percentage($grid2-gutter / $grid2-wrap);
          }
        }
      }
    }
  }
  &__tout-link {
    color: $color-black;
    font-family: $font--lars;
    text-decoration: none;
  }
  &__mobile-viewall {
    display: block;
    margin: 25px 0;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
}

.gnav-section-tout {
  margin-#{$rdirection}: $gnav-mobile-hpad--outer;
  order: 2;
  padding-top: 20px;
  @include breakpoint($landscape-up) {
    flex: 0 percentage($grid1-col / $grid1-wrap-full); // Tout is always small col width on pc regardless of grid selection.
    margin-#{$rdirection}: 0;
    padding-top: 0;
  }
  .gnav-section-formatter--tout-enabled.gnav-section-formatter--mobile-tout-first & {
    @include breakpoint($landscape-down) {
      order: 1;
      padding-top: 0;
    }
  }
  &__headline {
    @include title--h4;
    margin-bottom: 24px;
  }
  &__ctas {
    color: $color-grey;
    display: flex;
    justify-content: space-between;
    padding-bottom: $container-pad-small;
  }
  &__cta-link {
    color: $color-grey;
    font-family: $font--lars;
    text-decoration: none;
  }
  &__image-wrapper {
    padding: 10px 0 $container-pad-small 0;
  }
  &__image {
    width: 100%;
  }
  &__subcategory-links {
    color: $color-grey;
    font-family: $font--lars;
    a {
      color: $color-black;
      text-decoration: none;
      &:hover {
        color: $color-gnav-grey;
      }
    }
    &--first {
      margin-bottom: 20px;
      @include breakpoint($landscape-up) {
        margin-bottom: 44px;
      }
    }
  }
  &__subcategory-header {
    font-weight: 700;
    padding-bottom: $container-pad-small;
  }
  &__subcategory-link {
    font-family: $font--lars;
    line-height: get-line-height(12px, 17px);
    padding-bottom: 12px;
    a {
      color: $color-grey;
      text-decoration: none;
    }
  }
}
